import axios from 'axios';

const imageUpload = document.getElementById('imageUpload');
const imagePreview = document.getElementById('imagePreview');
const result = document.getElementById('result');
const scoreProgress = document.getElementById('scoreProgress');
const scoreValue = document.getElementById('scoreValue');
const description = document.getElementById('description');

let score = null

imageUpload.addEventListener('change', function(e) {
    const file = e.target.files[0];
    if (file) {
        console.log('File selected:', file);
        const reader = new FileReader();
        reader.onload = function(e) {
            
            // Remove the data URL prefix to get just the base64 string
            const base64Image = e.target.result.split(',')[1];

            // Show loading spinner
            loadingText.style.display = 'block';
            result.style.display = 'none';
            
            // Send the base64 image to the API
            axios.post('https://api.jed.world/api/public/rateMyfit', {
                image_url: base64Image
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(function (response) {

                // Hide loading spinner
                loadingText.style.display = 'none';

                // Display the result
                scoreProgress.value = response.data.score;
                scoreValue.textContent = `Score: ${response.data.score}/100`;
                score = response.data.score;
                description.textContent = response.data.description;
                result.style.display = 'block';

                // Show share button
                shareButtonsWrapper.style.display = 'block';
            })
            .catch(function (error) {
                // Hide loading spinner
                loadingText.style.display = 'none';
                
                console.error('Error:', error);
                alert('An error occurred while rating your fit. Please try again.');
            });
        }
        reader.readAsDataURL(file);
    }
});


document.getElementById('shareButton').addEventListener('click', function() {
    // Check if the Web Share API is supported
    if (navigator.share) {
        navigator.share({
            title: 'Check out my outfit score!',
            text: `My outfit today scored ${score}/100 on Rate My Fit 🔥 Try yours now!`,
            url: 'https://ratemyfit.fun/', // Replace with the URL you want to share
        })
        .then(() => console.log('Successfully shared'))
        .catch((error) => console.log('Error sharing:', error));
    } else {
        alert('Sharing is not supported on this browser.'); // Fallback for unsupported browsers
    }
});